<template>
  <div>
    <CMessage :messages="message.base" />
    
    <div v-if="steps.current==0">
      <div class="card-a">
       <div class="max-w-[350px]"><CMemberCard :name="plan_price.card_name" /></div>
        <p>{{ plan_price.overview }}</p>
      </div>
      <div class="card-a">
        <StripeCards />
      </div>
      <CButton label="purchase" @click.prevent="create" :disabled="disabled" />
    </div>

    <div v-if="steps.current==1">
      Thank you!
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'
import StripeCards from '../StripeCards'

export default {
  name: "PlanRegister",
  components: { StripeCards },
  data: function () {
    return {
      message: {},
      plan_price: {
        id: null
      },
      steps: {
        names: ['license.payment', 'license.finish'],
        current: 0
      },
      disabled: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    subscription_params() {
      return {
        plan_price_id: this.plan_price.id,
        payment_type: 'stripe'
      }
    }
  },
  created() {
    if(!this.isLoggedIn) {
      this.$router.push({ name: 'login' });
    }
    this.show()
  },
  methods: {
    show(){
      ApiRequestor.get('plan_prices/' + this.$route.params.id).then(
        response => {
          this.message = {}
          this.plan_price = response.data
        },
        error => {
          this.message = error.response.data
        }
      );
    },
    create(){
      this.disabled = true
      ApiRequestor.post('current_user/subscriptions', this.subscription_params).then(
        response => {
          this.message = {}
          this.steps.current ++
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>