<template>
  <img class="w-full" :src="image_url" />
</template>

<script>
export default {
  name: "CMemberCard",
  props: {
    name: String
  },
  computed: {
    image_url() {
      return '/images/membership/' + this.name + '.svg'
    }
  }
}
</script>