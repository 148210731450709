<template> 
  <div class="py-1"></div>
  <!-- Term1 -->
  <label class="title-a menu-group-active">{{ $t('activerecord.attributes.contract_visa.term1') }}</label>
  <CInputTag
    v-if="isNew"
    v-model="contract.term1"
    :options="meta.term1"
    :errors="message.term1"
  />
  <div v-else class="flex">
    <span class="tag-item-active" v-if="contract.term1">{{ $t('enum.contract.' + contract.code + '.term1.'+ contract.term1) }}</span>
  </div>
  <!-- Term2 -->
  <template v-if="contract.term1">
  <label class="title-a menu-group-active">{{ $t('activerecord.attributes.contract_visa.term2') }}</label>
  <CInputTag
    v-if="isNew"
    v-model="contract.term2"
    :options="meta.term2"
    :errors="message.term2"
  />
  <div v-else class="flex">
    <span class="tag-item-active" v-if="contract.term2">{{ $t('enum.contract.' + contract.code + '.term2.'+ contract.term2) }}</span>
  </div>
  </template>
  <!-- Helps  -->
  <div v-if="help">
    <a :href="help" target="_blank" class="link-a">
      <mdicon name="hand-pointing-right" />
      {{ $t('activerecord.attributes.contract_' + this.code + '.help') }}
      <mdicon name="open-in-new" />
  </a>
  </div>
  <CMessage :messages="message.base" />
  <div v-if="isNew">
    <template v-if="contract.term1 && contract.term2">
      <!-- Request  -->
      <div class="card-a bg-warning">{{ $t('views.help.contract.' + this.code + '.request') }}</div>
      <CTextArea
        v-model="contract.contact_message_body"
        rows="10"
        :errors="message.title"
      />
      <CButton
        label="request"
        @click.prevent="request()"
        :disabled="!contract.contact_message_body"
      />
    </template>
  </div>
  <div v-else>
    <!-- User Upload  -->
    <div v-if="true">
      <h3 class="title-a menu-group-active">{{ $t('activerecord.attributes.contract_visa.user_contract_attachments') }}</h3>
      <div class="flex grid grid-cols-2 sm:grid-cols-6">
        <div 
          v-for="(item, index) in contract.user_contract_attachments"
          :key="index"
          class="flex card-a mr-2 rounded-full relative"
        >
          <a
            :href="item.url"
            target="_blank"
            class="underline"
          >{{ item.name }}</a><a class="absolute right-[-10px] top-[-10px] cursor-pointer" @click.prevent="deleteFile(index, item.id)"><mdicon name="close-circle-outline" /></a>
        </div>
      </div>
      <CButton v-if="filesSelectorShow" label="add" icon="file-plus-outline" @click.prevent="filesSelectorShow=false" class="btn-a"/>
      <template v-else>
        <CInputFile v-model="files" multiple :errors="message.file" />
        <CButton @click.prevent="upload()" :disabled="files.length==0" label="upload"/>
        <CButton label="close" icon="close" @click.prevent="filesSelectorShow=true" class="ml-2 link-a text-danger"/>
      </template>
    </div>
    <!-- Invoices  -->
    <div v-if="false">
      <h3 class="title-a menu-group-active">Hop dong invoices</h3>
      <div v-for="(invoice, index) in contract.contract_invoices" :key="index">
        {{  invoice.reason }}
      </div>
    </div>
    <!-- Admin Upload  -->
    <div v-if="false">
      <h3 class="title-a menu-group-active">Tai lieu ket qua</h3>
      <a
        v-for="(item, index) in contract.admin_contract_attachments"
        :key="index"
        :href="item.url"
        target="_blank"
      >{{ item.name }}</a>
    </div>
    <!-- Message  -->
    <div v-if="!isNew">
      <h3 class="title-a menu-group-active">{{ $t('activerecord.models.contact_message') }}</h3>
      <ContactMessageThread
        :back-btn="false"
        :contact-message-id="contact_message_id"
      />
    </div>
  </div>
</template>
<script>
import ApiRequestor from '../../common/ApiRequestor'
import ContactMessageThread from '../ContactMessageThread'
export default {
  name: "Contract",
  props :{
    code: String
  },
  components: { ContactMessageThread },
  computed: {
    isNew() {
      return this.contract.id == null ? true : false
    },
    help() {
      if(this.contract.term1 && this.contract.term2) {
        if(this.meta.help2[this.contract.term2].includes('https')) {
          return this.meta.help2[this.contract.term2]
        } else {
          return this.meta.help1[this.contract.term1]
        }
      } else {
        return null
      }
    },
    contact_message_id() {
      return this.contract.contact_message_id;
    }
  },
  data() {
    return {
      message: {},
      contract: {
        id: null,
        code: this.code,
        term1: null,
        term2: null,
        user_contract_attachments: [],
        admin_contract_attachments: [],
        contact_message_body: null,
        contact_message_id: null,
        contract_invoices: []
      },
      filesSelectorShow: true,
      files: [],
      meta: {
        term1: [],
        term2: [],
        help1: {},
        help2: {}
      }
    }
  },
  created() {
    this.contract.id = this.$route.params.cid
    this.loadMeta()
    if(!this.isNew) {
      this.loadContract()
    }
  },
  methods: {
    request() {
      ApiRequestor.post('current_user/contracts', {contract: this.contract} ).then(
        response => {
          this.$router.push({name: 'visa', params: {cid: response.data.id }})
        },
        error => {
          this.message = error.response.data
        }
      )
    },
    loadMeta() {
      ApiRequestor.get('contracts?code=' + this.contract.code).then(
        response => {
          this.meta = response.data.meta

          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    },
    async loadContract() {
      ApiRequestor.get('current_user/contracts/' + this.contract.id ).then(
        response => {
          this.contract = response.data
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      )
    },
    deleteFile(index, id) {
      if(window.confirm("Are you sure?")){
        ApiRequestor.delete('current_user/contract_attachments/' + id).then(
        response => {
          this.contract.user_contract_attachments.splice(index, 1)
          this.filesSelectorShow = true
        },
        error => {
          this.message = error.response.data
        }
      );
      }
    },
    upload(){
      let formData = new FormData();
      Array.from(this.files).forEach(file => {
        formData.append('user_contract_attachments[]', file)
      });
      ApiRequestor.upload('current_user/contracts/' + this.contract.id + '/upload', formData).then(
        response => {
          window.location.reload();
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  },
}
</script>