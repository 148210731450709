<template>
  <CMessage :messages="message.base" />
  <div class="grid md:grid-cols-3">
    <div v-for="(plan, index) in plans" :key="index" class="card-a mx-1">
      <div><CMemberCard :name="plan.card_name" /></div>
      <div>
        <div v-for="(item, index) in plan.description" :key="index">
          <mdicon v-if="item=='○'" name="check" class="text-primary"></mdicon>
          <mdicon v-else name="close" class="text-danger"></mdicon>
          {{ description[index] }}
        </div>
      </div>
      <div>
        <router-link
          v-if="plan.code=='free' && !isLoggedIn"
          :to="{ name: 'register' }"
          class="btn-a-outline" >{{ $t('views.action.register') }}
        </router-link>
        <router-link
          v-for="(plan_price, index2) in plan.plan_prices"
          :to="{ name: 'plan_register', params: {id: plan_price.id} }"
          class="btn-a" >{{ $t('views.action.purchase') }} <br> {{ plan_price.overview }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ApiRequestor from '../../common/ApiRequestor'

export default {
  name: "Plans",
  data: function () {
    return {
      message: {},
      description: [],
      plans: []
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      ApiRequestor.get('plans').then(
        response => {
          this.description = response.data.description
          this.plans = response.data.plans
          this.message = {}
        },
        error => {
          this.message = error.response.data
        }
      );
    }
  }
}
</script>